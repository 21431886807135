import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "supplement-detail__remove-p" }
const _hoisted_2 = { class: "l-container" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-grid" }
const _hoisted_7 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_8 = { for: "supplement.name" }
const _hoisted_9 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_10 = { for: "supplement.internalName" }
const _hoisted_11 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_12 = { for: "supplement.types" }
const _hoisted_13 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_14 = { for: "supplement.description" }
const _hoisted_15 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_16 = { for: "types" }
const _hoisted_17 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_18 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_19 = { for: "amount" }
const _hoisted_20 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_21 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_22 = { for: "numberOfTakes" }
const _hoisted_23 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_24 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_25 = { for: "startDate" }
const _hoisted_26 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_27 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_28 = { for: "endDate" }
const _hoisted_29 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_30 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_31 = { for: "frequencyOfTakes" }
const _hoisted_32 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_33 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_34 = { for: "supplement.shoppingUrl" }
const _hoisted_35 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_36 = { for: "observations" }
const _hoisted_37 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_38 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_39 = { for: "supplement.discountCode" }
const _hoisted_40 = {
  key: 1,
  class: "card-footer"
}

import { useI18n } from 'vue-i18n';
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import {
  PatientSupplementDto,
  patientSupplementForm,
  isPatientFormUpdateValid,
  SupplementUnit,
  SupplementType,
} from '@/models/Supplement';
import { supplementsService } from '@/services/SupplementsService';
import { ServiceError } from '@/services/util/ServiceError';
import HeadingComponent from '@/components/HeadingComponent.vue';
import DeletePatientSupplementModal from './DeletePatientSupplementModal.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientSupplementDetail',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const toast = useToast();

const { t } = useI18n();

const breadcrumb = ref([] as Array<any>);
const editMode = ref(false);
const waitSubmit = ref(false);
const showValidation = ref(false);

const patientId = route.params.patientId as string;
const supplementId = route.params.supplementId as string;

const supplement = ref();
const supplementPatientFormDto = ref<PatientSupplementDto>();
const deletePatientSupplementModalRef = ref();

const valid = computed(() => {
  if (!validateForm?.value) {
    return false;
  }
  return isPatientFormUpdateValid(validateForm?.value);
});

const minEndDate = computed(() => {
  return supplementPatientFormDto.value?.startDate || new Date();
});

const validateForm = computed(() => {
  if (!supplementPatientFormDto.value) {
    return;
  }
  return patientSupplementForm(supplementPatientFormDto.value);
});

const labelAmount = computed(() => {
  if (supplementPatientFormDto.value && supplementPatientFormDto.value.unit) {
    return `${t('supplementation.patient.create-modal.amount', {
      unit: t(`supplementation.unit.${supplementPatientFormDto.value.unit}`),
    })}`;
  } else {
    return t('supplementation.patient.create-modal.amount', { unit: '' });
  }
});

const unitsOptions = computed(() => {
  return Object.keys(SupplementUnit).map((key) => ({
    label: t(`supplementation.unit.${key}`),
    value: key,
  }));
});

const numberOfTakesOptions = computed(() => {
  const array: { label: string; value: number }[] = [];
  for (let i = 1; i <= 10; i += 1) {
    array.push({ label: i.toString(), value: i });
  }
  return array;
});

onMounted(async () => {
  await loadSupplement();
});

const loadSupplement = async () => {
  const result = await supplementsService.findByIdByPatient(supplementId, patientId);
  if (!(result instanceof ServiceError)) {
    breadcrumb.value = [
      { label: t('breadcrumb.supplements'), to: `/patient/${patientId}/supplements` },
      {
        label: result.supplement.name,
        to: '#',
        disabled: true,
      },
    ];
    supplement.value = result;
    supplementPatientFormDto.value = new PatientSupplementDto(result);
  }
};

const onDeleteSupplement = () => {
  deletePatientSupplementModalRef.value?.openDialog();
};

const onSupplementDeleted = () => {
  router.push(`/patient/${patientId}/supplements`);
};

const onCancelEdition = async () => {
  editMode.value = false;
  setInitialVaules();
};

const setInitialVaules = () => {
  if (supplementPatientFormDto.value) {
    supplementPatientFormDto.value = new PatientSupplementDto(supplement.value);
  }
};

const onSubmit = async () => {
  showValidation.value = true;
  if (!valid.value) {
    return;
  }
  try {
    waitSubmit.value = true;
    if (supplementPatientFormDto.value) {
      supplement.value = await supplementsService.updateByPatient(
        supplement.value.id,
        supplementPatientFormDto.value,
        patientId,
      );
    }
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditSupplement')}`, life: 3000 });
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorEditSupplement')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } finally {
    waitSubmit.value = false;
    editMode.value = false;
  }
};

const supplementOptions = computed(() => {
  return Object.keys(SupplementType).map((key) => ({
    label: t(`supplementation.type.${key}`),
    value: key,
  }));
});

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (supplement.value)
      ? (_openBlock(), _createBlock(HeadingComponent, {
          key: 0,
          title: supplement.value.supplement.name,
          "heading-icon": "icon-capsules",
          breadcrumb: breadcrumb.value
        }, {
          right: _withCtx(() => [
            _createElementVNode("div", {
              class: "supplement-detail__remove",
              onClick: onDeleteSupplement
            }, [
              _cache[14] || (_cache[14] = _createElementVNode("i", {
                class: "icon-trash_full supplement-detail__remove-icon",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('common.delete')), 1)
            ])
          ]),
          _: 1
        }, 8, ["title", "breadcrumb"]))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(HeadingComponent, {
          title: _ctx.$t('supplementation.info-supplement'),
          divider: false,
          "sub-heading": true
        }, {
          right: _withCtx(() => [
            _renderSlot(_ctx.$slots, "headingActions", {}, () => [
              _withDirectives((_openBlock(), _createBlock(_component_Button, {
                class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", editMode.value ? 'p-disabled' : '']),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (editMode.value = !editMode.value))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-edit",
                    "aria-label": _ctx.$t('common.edit'),
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_4)
                ]),
                _: 1
              }, 8, ["class"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.edit'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ]),
          _: 3
        }, 8, ["title"]),
        (supplementPatientFormDto.value)
          ? (_openBlock(), _createElementBlock("form", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('supplementation.create-modal.name')), 1),
                  _createVNode(HealzInput, {
                    id: "supplement.name",
                    modelValue: supplement.value.supplement.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((supplement.value.supplement.name) = $event)),
                    modelModifiers: { trim: true },
                    editable: false
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('supplementation.create-modal.internalName')), 1),
                  _createVNode(HealzInput, {
                    id: "supplement.internalName",
                    modelValue: supplement.value.supplement.internalName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((supplement.value.supplement.internalName) = $event)),
                    modelModifiers: { trim: true },
                    editable: false
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('supplementation.create-modal.type')), 1),
                  _createVNode(_component_MultiSelect, {
                    id: "supplement.types",
                    modelValue: supplement.value.supplement.types,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((supplement.value.supplement.types) = $event)),
                    options: supplementOptions.value,
                    "option-label": "label",
                    "option-value": "value",
                    class: _normalizeClass([
                'multiselect-custom',
                {
                  'no-editable': true,
                },
              ]),
                    editable: false
                  }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('supplementation.create-modal.description')), 1),
                  _createVNode(HealzInput, {
                    id: "supplement.description",
                    modelValue: supplement.value.supplement.description,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((supplement.value.supplement.description) = $event)),
                    modelModifiers: { trim: true },
                    editable: false
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.unit')) + "*", 1),
                  _createVNode(_component_Dropdown, {
                    id: "types",
                    modelValue: supplementPatientFormDto.value.unit,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((supplementPatientFormDto.value.unit) = $event)),
                    options: unitsOptions.value,
                    "option-label": "label",
                    "option-value": "value",
                    class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.unit.valid, 'no-editable': !editMode.value })
                  }, null, 8, ["modelValue", "options", "class"]),
                  (showValidation.value && !validateForm.value?.unit.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.$t(`${validateForm.value?.unit.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, _toDisplayString(labelAmount.value) + "*", 1),
                  _createVNode(HealzInput, {
                    id: "amount",
                    modelValue: supplementPatientFormDto.value.amount,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((supplementPatientFormDto.value.amount) = $event)),
                    modelModifiers: { trim: true },
                    inputType: 'InputNumber',
                    required: "true",
                    editable: editMode.value,
                    "max-fraction-digits": 2,
                    class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.amount?.valid })
                  }, null, 8, ["modelValue", "editable", "class"]),
                  (showValidation.value && !validateForm.value?.amount.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_20, _toDisplayString(_ctx.$t(`${validateForm.value?.amount.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.numberOfTakes')) + "*", 1),
                  _createVNode(_component_Dropdown, {
                    id: "numberOfTakes",
                    modelValue: supplementPatientFormDto.value.numberOfTakes,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((supplementPatientFormDto.value.numberOfTakes) = $event)),
                    options: numberOfTakesOptions.value,
                    "option-label": "label",
                    "option-value": "value",
                    class: _normalizeClass({
                'p-invalid': showValidation.value && !validateForm.value?.numberOfTakes.valid,
                'no-editable': !editMode.value,
              })
                  }, null, 8, ["modelValue", "options", "class"]),
                  (showValidation.value && !validateForm.value?.numberOfTakes.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_23, _toDisplayString(_ctx.$t(`${validateForm.value?.numberOfTakes.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.startDate')) + "*", 1),
                  _createVNode(_component_Calendar, {
                    id: "startDate",
                    modelValue: supplementPatientFormDto.value.startDate,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((supplementPatientFormDto.value.startDate) = $event)),
                    class: _normalizeClass({
                'p-invalid': showValidation.value && !validateForm.value?.startDate.valid,
                'no-editable': !editMode.value,
              })
                  }, null, 8, ["modelValue", "class"]),
                  (showValidation.value && !validateForm.value?.startDate.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_26, _toDisplayString(_ctx.$t(`${validateForm.value?.startDate.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.endDate')) + "*", 1),
                  _createVNode(_component_Calendar, {
                    id: "endDate",
                    modelValue: supplementPatientFormDto.value.endDate,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((supplementPatientFormDto.value.endDate) = $event)),
                    minDate: minEndDate.value,
                    class: _normalizeClass({
                'p-invalid': showValidation.value && !validateForm.value?.endDate.valid,
                'no-editable': !editMode.value,
              })
                  }, null, 8, ["modelValue", "minDate", "class"]),
                  (showValidation.value && !validateForm.value?.endDate.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_29, _toDisplayString(_ctx.$t(`${validateForm.value?.endDate.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.frequencyOfTakes')) + "*", 1),
                  _createVNode(HealzInput, {
                    id: "frequencyOfTakes",
                    modelValue: supplementPatientFormDto.value.frequencyOfTakes,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((supplementPatientFormDto.value.frequencyOfTakes) = $event)),
                    modelModifiers: { trim: true },
                    required: "true",
                    class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.frequencyOfTakes.valid }),
                    editable: editMode.value
                  }, null, 8, ["modelValue", "class", "editable"]),
                  (showValidation.value && !validateForm.value?.frequencyOfTakes.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_32, _toDisplayString(_ctx.$t(`${validateForm.value?.frequencyOfTakes.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t('supplementation.create-modal.shoppingUrl')), 1),
                  _createVNode(HealzInput, {
                    id: "supplement.shoppingUrl",
                    modelValue: supplement.value.supplement.shoppingUrl,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((supplement.value.supplement.shoppingUrl) = $event)),
                    modelModifiers: { trim: true },
                    editable: false
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.observations')), 1),
                  _createVNode(_component_Textarea, {
                    id: "observations",
                    modelValue: supplementPatientFormDto.value.observations,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((supplementPatientFormDto.value.observations) = $event)),
                    class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.observations.valid, 'no-editable': !editMode.value }),
                    rows: "5",
                    "auto-resize": true,
                    type: "text"
                  }, null, 8, ["modelValue", "class"]),
                  (showValidation.value && !validateForm.value?.observations.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_37, _toDisplayString(_ctx.$t(`${validateForm.value?.observations.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.$t('supplementation.create-modal.discountCode')), 1),
                  _createVNode(HealzInput, {
                    id: "supplement.discountCode",
                    modelValue: supplement.value.supplement.discountCode,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((supplement.value.supplement.discountCode) = $event)),
                    modelModifiers: { trim: true },
                    editable: false
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (editMode.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
              _createVNode(_component_Button, {
                class: "p-button-secondary p-button-medium",
                label: _ctx.$t('common.cancel'),
                onClick: onCancelEdition
              }, null, 8, ["label"]),
              _createVNode(_component_Button, {
                class: "p-button p-button-info p-button-medium",
                loading: waitSubmit.value,
                label: _ctx.$t('common.save'),
                onClick: onSubmit
              }, null, 8, ["loading", "label"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(DeletePatientSupplementModal, {
      ref_key: "deletePatientSupplementModalRef",
      ref: deletePatientSupplementModalRef,
      supplementId: _unref(supplementId),
      patientId: _unref(patientId),
      onSupplementDeleted: onSupplementDeleted
    }, null, 8, ["supplementId", "patientId"])
  ], 64))
}
}

})